<template>
    <div class="ele-body">
        <el-card shadow="never">
            <!-- 搜索表单 -->
            <div >
            <el-form :model="table.where" class="ele-form-search d-flexspabet"
                @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
             
                    <!-- <el-button @click="showEdit=true" class="ele-btn-icon addbtn" size="small"
						v-if="permission.includes('sys:rreach:add')">添加违约金设置
					</el-button> -->
           
                <div class="d-flex" style="display: flex;">
                    <el-button style="height: 40px;" @click="showEdit=true" class="ele-btn-icon addbtn mb-20" size="small"
                        v-if="permission.includes('sys:keyword:add')">添加地址关键词
                    </el-button>
           
                    <el-form-item label="叫单搜索关键字:" label-width="130px" class="w-300">
                        <el-input v-model="table.where.search_keyword" placeholder="请输入叫单搜索关键字" clearable />
                    </el-form-item>
                    <el-form-item label="校准搜索关键词:" label-width="130px" class="w-300">
                        <el-input v-model="table.where.update_keyword" placeholder="请输入校准搜索关键词" clearable />
                    </el-form-item>
                    <!-- <el-form-item label="添加日期:" label-width="100px"> -->
                    <!-- <el-date-picker v-model="table.where.create_time" type="date" value-format="yyyy-MM-dd" placeholder="选择开始时间"
                            clearable>
            </el-date-picker>
            -
            <el-date-picker v-model="table.where.update_time" type="date" value-format="yyyy-MM-dd" placeholder="选择结束时间"
                            clearable>
            </el-date-picker> -->
                    <!-- </el-form-item> -->
                    <el-form-item label="省:" label-width="35px" class="w-170">
                        <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
                            placeholder="请选择省" clearable>
                            <el-option v-for="option in provArr" :label="option.name" :value="option.pid"
                                :key="option.pid"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="市:" label-width="35px" class="w-150">
                        <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
                            placeholder="请选择市" clearable>
                            <el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
                                :key="option.cid"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
                        <el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder="请选择县/区" clearable>
                            <el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
                                :key="option.aid"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                            class="ele-btn-icon">搜索</el-button>
                        <!-- <el-button  class="ele-btn-icon" @click="(table.where={})&&$refs.table.reload()">重置</el-button> -->
                    </el-form-item>
                    <el-form-item style="margin-left: 10px;">
                        <!-- <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                            class="ele-btn-icon">搜索</el-button> -->
                        <el-button  class="ele-btn-icon" @click="(table.where={})&&$refs.table.reload()">重置</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>
            <!-- 数据表格 -->
            <ele-data-table ref="table" fit="true" :config="table" :choose.sync="choose" :stripe=true>
                <template slot-scope="{index}">
                    <el-table-column type="selection" width="45" align="center" fixed="left" />
                    <el-table-column type="index" :index="index" label="编号"  align="center" fixed="left" width="70" show-overflow-tooltip />
                    <el-table-column prop="pid" label="省" align="center" show-overflow-tooltip min-width="50" />
                    <el-table-column prop="cid" label="市" align="center" show-overflow-tooltip min-width="50" />
                    <el-table-column prop="aid" label="区" align="center" show-overflow-tooltip min-width="50" />
                    <el-table-column prop="search_keyword" label="用户叫单搜索关键词" align="center" show-overflow-tooltip
                        min-width="80" />
                    <el-table-column prop="update_keyword" label="校准搜索关键词" align="center" show-overflow-tooltip />
                    <el-table-column label="添加时间" align="center" show-overflow-tooltip min-width="100">
                        <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
                    </el-table-column>
                    <el-table-column label="更新时间" align="center" show-overflow-tooltip min-width="100">
                        <template slot-scope="{row}">{{ row.update_time*1000 | toDateString }}</template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="160px" align="center" :resizable="false" fixed="right">
                        <template slot-scope="{row}">
                            <!-- <el-link slot="reference" @click="set_status(row)"
                                :icon="row.open_status==1?'el-icon-error':'el-icon-success'"
                                :type="row.open_status==1?'danger':'primary'" :underline="false"
                                v-if="permission.includes('sys:rreach:start')">
                                {{row.open_status==1?'关闭':row.open_status==0?'开启':''}}
                            </el-link> -->
                            <el-link @click="edit(row)" slot="reference" icon="el-icon-edit" type="primary" :underline="false"
                                v-if="permission.includes('sys:keyword:edit')">编辑</el-link>
                            <el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger"
                                :underline="false" v-if="permission.includes('sys:keyword:delete')">删除</el-link>
                        </template>
                    </el-table-column>
                </template>
            </ele-data-table>
        </el-card>
        <!-- 编辑弹窗 -->
        <!--添加/编辑弹窗 -->
        <el-dialog v-dialogDrag :title="editForm.id?'查看地址关键词':'添加地址关键词'" :visible.sync="showEdit" @closed="editForm={}"
            :destroy-on-close="true" :lock-scroll="false">
            <el-card shadow="never">
                <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="180px">
                    <el-form-item label="用户叫单搜索关键词：" prop="search_keyword">
                        <el-input v-model="editForm.search_keyword" controls-position="right" placeholder="" clearable
                            class="input163" />

                    </el-form-item>
                    <el-form-item label="校准搜索关键词：" prop="update_keyword">
                        <el-input v-model="editForm.update_keyword" controls-position="right" placeholder="" clearable
                            class="input163" />
                    </el-form-item>
                    <el-form-item label="请选择地区：" prop="pid">
                        <el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)" placeholder='请选择省'
                            class="selectStyle mr-10 mb-20" clearable>
                            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                                :label="option.name"></el-option>
                        </el-select>
                        <el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)"
                            placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable>
                            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                                :label="option.name"></el-option>
                        </el-select>
                        <el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)"
                            placeholder="请选择区/县" class="selectStyle mr-10 mb-20" clearable>
                            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                                :label="option.name"></el-option>
                        </el-select>
                    </el-form-item>

                    <!-- <el-form-item label="用户叫单搜索关键词：" prop="search_keyword">
                        <el-input v-model="editForm.search_keyword" controls-position="right" placeholder="" clearable
                            class="input163" />

                    </el-form-item>
                    <el-form-item label="校准搜索关键词：" prop="update_keyword">
                        <el-input v-model="editForm.update_keyword" controls-position="right" placeholder="" clearable
                            class="input163" />
                    </el-form-item> -->
                </el-form>
            </el-card>
            <div slot="footer">
                <el-button @click="showEdit=false">取消</el-button>
                <el-button type="primary" @click="save">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    mapGetters
} from "vuex";
export default {
    name: "SysLimitNumbers",
    watch: {
    $route() {
      //切换标签时
      this.handleRefreshTable();
    }
  },
    data() {
        return {
            table: {
                url: '/user/search_keyword',
                where: {}
            }, // 表格配置
            choose: [], // 表格选中数据
            showEdit: false, // 是否显示表单弹窗
            editForm: {}, // 表单数据
            editRules: { // 表单验证规则
                pid: [{
                    required: true,
                    message: '请选择省份',
                    trigger: 'change'
                }],
                search_keyword: [{
                    required: true,
                    message: '请输入用户叫单关键词',
                    trigger: 'blur'
                }]
            },
            provArr: [],
            cityArr: [],
            districtArr: [],
            activeName: 'first',
            activeEdit: 'run-driver',
            pid: '',
            cid: '',
            aid: '',
            changePro: false,
            changeCity: false,
            changeArea: false,

        }
    },
    created() {


        let _this = this;
        this.$http.get('/common/province_list').then(res => {
            let data = JSON.parse(res.data)
            this.provArr = data
        })
        window.onresize = function () {
      _this.handleRefreshTable();
    };

    },
    computed: {
        ...mapGetters(["permission"]),
    },
    updated() {
    //数据改变时
    this.handleRefreshTable();
  },
    mounted() { },
    methods: {


        /**
         *选择省
         **/
        handleChangeProv(e) {
            /** 获取被选省份的pid**/
            let pid = ''
            this.provArr.forEach(function (item) {
                if (item.pid == e) {
                    pid = item.pid
                }
            })
            /** 根据被选省份的pid获取省市下面的市**/
            this.$http.post('/common/city_list', {
                pid: pid
            }).then(res => {
                let data = JSON.parse(res.data)
                this.cityArr = data
                /** 选择省份清空市县**/
                this.table.where.cid = ''
                this.table.where.aid = ''
            })
        },
        /**
         *选择市
         **/
        handleChangeCity(e) {
            if (e) {
                /** 获取被选市的cid**/
                let cid = ''
                this.cityArr.forEach(function (item) {
                    if (item.cid == e) {
                        cid = item.cid
                    }
                })
                /** 根据被选市的cid获取市下面的县**/
                this.$http.post('/common/area_list', {
                    cid: cid
                }).then(res => {
                    let data = JSON.parse(res.data)
                    this.districtArr = data
                    /** 选择省份清空县**/
                    this.table.where.aid = ''
                })
            } else {
                // this.table.where.pid = ''
                this.table.where.aid = ''
            }
        },

        /**
         *修改省
         **/
        handleChangeProv1(e) {
            /** 获取被选省份的pid**/
            if (e) {
                let pid = ''
                let that = this
                this.changePro = true
                this.provArr.forEach(function (item) {
                    if (item.pid == e) {
                        pid = item.pid
                    }
                })
                /** 根据被选省份的pid获取省市下面的市**/
                this.$http.post('/common/city_list', {
                    pid: pid
                }).then(res => {
                    let data = JSON.parse(res.data)
                    that.cityArr = data
                    /** 选择省份清空市县**/
                    this.editForm.cid = ''
                    this.editForm.aid = ''
                    this.editForm.cname = ''
                    this.editForm.aname = ''
                })
            } else {
                this.editForm.pid = ''
                this.editForm.cid = ''
                this.editForm.aid = ''
                this.editForm.pname = ''
                this.editForm.cname = ''
                this.editForm.aname = ''
            }

        },
        /**
         *修改市
         **/
        handleChangeCity1(e) {
            if (e) {
                /** 获取被选市的cid**/
                let cid = ''
                this.changeCity = true
                this.cityArr.forEach(function (item) {
                    if (item.cid == e) {
                        cid = item.cid
                    }
                })
                /** 根据被选市的cid获取市下面的县**/
                this.$http.post('/common/area_list', {
                    cid: cid
                }).then(res => {
                    let data = JSON.parse(res.data)
                    this.districtArr = data
                    /** 选择省份清空县**/
                    this.editForm.aid = ''
                    this.editForm.aname = ''
                })
            } else {
                // this.editForm.cname = ''
                this.editForm.aid = ''
                this.editForm.aname = ''
            }
        },
        handleDis() {
            this.changeArea = true
        },
        /**
         * 显示编辑
         */
        edit(row) {
            this.pid = row.pid
            this.cid = row.cid
            this.aid = row.aid

            this.editForm = row
            this.editForm.pid = row.pid
            this.editForm.cname=row.cid;
            this.editForm.aname =row.aid;
            this.showEdit = true;

            if (this.editForm.pid != '') {
                this.$http.post('/common/city_list', {
                    pid: this.editForm.pids
                 
                }).then(res => {
                    let cdata = JSON.parse(res.data)
                    this.cityArr = cdata
                })
            }
            if (this.editForm.cid != '') {
                this.$http.post('/common/area_list', {
                    cid: this.editForm.cids
                }).then(res => {
                    let adata = JSON.parse(res.data)
                    this.districtArr = adata
                })
            }

            // this.$http.get('/limitnumbers/info?id=' + row.id).then(res => {
            //   if (res.data.code === 0) {
            //     if(res.data.data.type==1){
            //       res.data.data.type='出租车'
            //     }else  if(res.data.data.type==2){
            //       res.data.data.type='拼车'
            //     }else if(res.data.data.type==3){
            //       res.data.data.type='帮我买'
            //     }else if(res.data.data.type==4){
            //       res.data.data.type='取送件'
            //     }else if(res.data.data.type==5){
            //       res.data.data.type='超市'
            //     }else {
            //       res.data.data.type='叫餐'
            //     }
            //     res.data.data.pid=res.data.data.pname
            //     res.data.data.cid=res.data.data.cname
            //     res.data.data.aid=res.data.data.aname
            //     this.editForm = res.data.data;
            //     this.showEdit = true;
            //     //console.log( this.editForm)
            //   } else {
            //     this.$message.error(res.data.msg);
            //   }
            // }).catch(e => {
            //   this.$message.error(e.message);
            // });
        },
        /**
         * 保存编辑
         */
        save() {
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true
                    });
                    //console.log(this.editForm)
                    // if (this.changePro) {
                    // 	this.editForm.pid = this.editForm.pid
                    // }
                    if (this.changeCity) {
                        this.editForm.cid = this.editForm.cname
                    }
                    if (this.changeArea) {
                        this.editForm.aid = this.editForm.aname
                    }
                    //console.log(this.editForm)
                    this.$http.post('/user/save_keyword', this.editForm).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.showEdit = false;
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                } else {
                    return false;
                }
            });
        },
        /**
         * 刪除(批量刪除)
         */
        remove(row) {
            //console.log(row)
            if (!row) { // 批量删除
                if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
                let ids = this.choose.map(d => d.id);
                this.$confirm('确定要删除选中的设置吗?', '提示', {
                    type: 'warning'
                }).then(() => {
                    const loading = this.$loading({
                        lock: true
                    });
                    this.$http.post('/user/keyword_delete', {
                        id: ids
                    }).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                }).catch(() => 0);
            } else { // 单个删除
                this.$confirm('确定要删除选中的设置吗?', '提示', {
                    type: 'warning'
                }).then(() => {
                    const loading = this.$loading({
                        lock: true
                    });
                    this.$http.post('/user/keyword_delete', {
                        id: row.id
                    }).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                })
            }
        },
    }
}
</script>

<style scoped>
.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
    width: 100%;
}

.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
    width: 100%;
}

.driverForm /deep/.el-form-item__content {
    margin-left: 0 !important
}

/deep/.el-tabs__nav {
    margin-left: 20px
}

.prompt {
    color: #FF0000;
    font-size: 14px;
}
</style>
